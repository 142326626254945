import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Email } from "../../components/Core";

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const CardImage = styled.div`
  max-width: 240px;
  min-width: 240px;
  min-height: 240px;
  max-height: 240px;
  overflow: hidden;
  border-radius: 500px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 29px;
`;

const TeamCard = ({ userImg, title, children, ...rest }) => (
  <Box
    className="text-center"
    pt="15px"
    px="30px"
    borderRadius={10}
    mb={4}
    {...rest}
  >
    <CardImage>
      <Img fixed={userImg} />
    </CardImage>
    <div className="text-center">
      <Title variant="card" fontSize="24px" letterSpacing={-0.75} my={1}>
        {title}
      </Title>
      <Text fontSize={2} lineHeight={1.75}>
        {children}
      </Text>
    </div>
  </Box>
);

const Team = () => {

  const data = useStaticQuery(graphql`
    query {
      jason: file(relativePath: { eq: "headshot_jason.jpg" }) {
        childImageSharp {
          fixed(width: 240) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      grahame: file(relativePath: { eq: "headshot_grahame.jpg" }) {
        childImageSharp {
          fixed(width: 240) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jim: file(relativePath: { eq: "headshot_jim.jpg" }) {
        childImageSharp {
          fixed(width: 240) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      derek: file(relativePath: { eq: "headshot_derek.jpg" }) {
        childImageSharp {
          fixed(width: 240) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <>
      <Section className="position-relative pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg="10" className="text-center pb-3">
              <div className="">
                <Title>Meet the team</Title>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col sm="6" md="6" lg="5" className="mt-3 mt-lg-4">
              <TeamCard userImg={data.jason.childImageSharp.fixed} title="Jason VenHuizen">
                Founder
                <br />
                <Email address="jason@sharptree.io" />
              </TeamCard>
            </Col>
            <Col sm="6" md="6" lg="5" className="mt-3 mt-lg-4">
              <TeamCard userImg={data.grahame.childImageSharp.fixed} title="Grahame Low Choy">
                Founder
                <br />
                <Email address="grahame@sharptree.io" />
              </TeamCard>
            </Col>
            <Col sm="6" md="6" lg="5" className="mt-3 mt-lg-4">
              <TeamCard userImg={data.jim.childImageSharp.fixed} title="Jim Ferns">
                Operations Manager
                <br />
                <Email address="james@sharptree.io" />
              </TeamCard>
            </Col>
            <Col sm="6" md="6" lg="5" className="mt-3 mt-lg-4">
              <TeamCard userImg={data.derek.childImageSharp.fixed} title="Derek Steele">
                Senior Maximo Developer
                <br />
                <Email address="derek@sharptree.io" />
              </TeamCard>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Team;
