import React from "react";
import PageTitle from "../components/PageTitle";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";
import Content from "../sections/about/Content";
import Sharptree from "../sections/about/Sharptree";
import Team from "../sections/about/Team";

const About = (props) => {
  return (
    <>
      <PageWrapper footerDark headerDark>
        <Seo
          title="Opqo | About | Mobile for Maximo"
          description="We know Maximo, we know mobile, and we think it should be simpler. Not just for users, but from all perspectives."
          pathname={props.location.pathname}
        />
        <PageTitle title="About us" titleDark>
          A new approach built on decades of mobile Maximo experience.
        </PageTitle>
        <Content />
        <Sharptree />
        <Team />
      </PageWrapper>
    </>
  );
};
export default About;
