import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ContentImg = styled(Box)`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 480px;
`;

const Content = () => {

  const data = useStaticQuery(graphql`
  query {
    good_vibes_only: file(relativePath: { eq: "good_vibes_only.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    here_there_everywhere: file(relativePath: { eq: "here_there_everywhere.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

  }
`)

  return (
    <>
      <Section>
        <Container>
          <Row className="justify-content-center pb-4">
            <Col lg="5">
              <Title>
                Our mission is to simplify mobile Maximo.
              </Title>
            </Col>
            <Col lg="7" className="pl-lg-5">
              <Text>
                For the past 15 years we’ve been developing and delivering successful mobile Maximo solutions,
                at scale and across a wide range of industries.
              </Text>
              <Text mt={3}>
                We’ve reflected on that experience, and we have a vision for a simpler, more flexible,
                more agile mobile Maximo.
              </Text>
              <Text mt={3}>
                Opqo is the realization of that vision.
              </Text>
            </Col>
          </Row>
          <Row className="my-5">
            <Col lg="4" sm="5" className="mb-4">
              <ContentImg>
                <Img fluid={data.good_vibes_only.childImageSharp.fluid} />
              </ContentImg>
            </Col>
            <Col lg="8" sm="7" className="mb-4">
              <ContentImg>
                <Img fluid={data.here_there_everywhere.childImageSharp.fluid} />
              </ContentImg>
            </Col>
          </Row>
          <Row className="justify-content-center pb-4">
            <Col lg="5">
              <Title>
                Simpler from all perspectives.
              </Title>
            </Col>
            <Col lg="7" className="pl-lg-5">
              <Text>
                Delivering a simple to use mobile solution is only the first step.
                We’re simplifying the entire experience.
              </Text>
              <Text mt={4}>
                Simpler to purchase, deploy and operate.
                <br />
                Simpler to get information and support.
                <br />
                Simpler to adjust based on your business needs.
              </Text>
              <Text mt={4}>
                Worry about your business, not your software.
              </Text>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Content;
